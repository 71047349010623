// Here you can add other styles
.bg-main{
    background: #79f3c6;
  }
.btn-primary {
    color: #fff;
    background-color: #11d200;
    border-color: #00a242;
}

.c-sidebar-brand-full{
  position: relative;
  top: 10px;
}

.i-avatar {
  width: 0px;
  height: 0px;
}

.i-avatar-img{
  width: 23px;
}

.tmp-avatar-img{
  height: 35px;
  width: 35px;
}
input, select {
  font-size: 16px!important
}

.card-header-actions-left {
  display: inline-block;

  @include ltr {
    float: left;
    margin-left: - ($spacer / 4);
  }
  @include rtl {
    float: left;
    margin-left: - ($spacer / 4);
  }
}

.card-header-action-left {
  padding: 0 ($spacer / 4);
  color: $gray-600;

  &:hover {
    color: $body-color;
    text-decoration: none;
  }
}
